import React, { useEffect, useState } from 'react';
import {
  addNewBrokerSuperBid,
  getProducersForBroker,
} from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { format } from 'date-fns';
import locationOptions from '../../utils/districts.json';
import * as app_consts from '../../utils/constants';
import { convertToTitleCase } from '../../utils/functionalUtils';
import { useParams } from 'react-router-dom';

const BidModal = ({ showModal, closeModal, fetchBrokerBids }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { entity_id } = useParams();

  const [currentProducers, setCurrentProducers] = useState([]);
  const [producersData, setProducersData] = useState([]);
  const [commodityOptions, setCommodityOptions] = useState([]);

  const ProducerList = useSelector(
    (state) => state.getProducersAndWarehouseReceiptsList
  );

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem('account')) || {};
    const brokerProfile = account.profiles.find(
      (profile) => profile.type === 'broker' && profile?.entity_id === entity_id
    );
    const brokerId = brokerProfile?.entity_id;
    dispatch(getProducersForBroker(brokerId));
  }, []);

  useEffect(() => {
    if (ProducerList.status !== app_consts.IDLE)
    {
      if (ProducerList.isLoading)
      {
        setLoading(true);
      } else
      {
        if (ProducerList.error)
        {
          setError(ProducerList.error);
        } else
        {
          setProducersData(ProducerList?.data?.data);
          setLoading(false);
        }
      }
    }
  }, [ProducerList]);

  const producerOptions = currentProducers?.map((producer) => ({
    value: producer.id,
    label: producer.producer_id + ' ' + '-' + ' ' + producer.contact_name,
  }));

  const handleProducerChange = (selectedOption) => {
    formik.setFieldValue('producer_id', selectedOption ? selectedOption?.value : '');

    const selectedProducer = currentProducers?.find(producer => producer?.id === selectedOption?.value);
    const commodities = selectedProducer ? selectedProducer?.commodities : [];
    formik.setFieldValue('commodity', '');

    setCommodityOptions(commodities.map(commodity => ({
      value: commodity,
      label: commodity,
    })));
  };

  const initialValues = {
    customer_name: '',
    producer_id: '',
    receipt_number: '',
    date_to_be_placed_on_platform: '',
    date_to_be_withdrawn_from_platform: '',
    commodity: '',
    unit_of_measure: '',
    volume: '',
    volume_on_offer: '',
    requested_unit_price: '',
    quality: '',
    other_documentation_required: '',
    order_type: '',
    special_terms_and_conditions: '',
    location: '',
    delivery: '',
    history: [],
  };
  const validationSchema = yup.object().shape({
    // producer_id: yup.string().required('Client ID is required'),
    date_to_be_placed_on_platform: yup
      .string()
      .required('Date to be placed on platform is required'),
    date_to_be_withdrawn_from_platform: yup
      .string()
      .required('Date to be withdrawn from platform is required'),
    commodity: yup.string().required('Commodity is required'),
    // unit_of_measure: yup.string().required('Unit of measure is required'),
    // volume: yup.string().required('Volume is required'),
    volume_on_offer: yup.string().required('Amount is required'),
    requested_unit_price: yup.string(),
    quality: yup.string().required('Quality is required'),
    other_documentation_required: yup.string(),
    order_type: yup.string().required('Order Type is required'),
    special_terms_and_conditions: yup.string(),
    location: yup.string().required('Location is required'),
    delivery: yup.string().required('Delivery is required'),
  });


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError('');
      try
      {
        const timestamp = new Date();
        const formattedTimestamp = format(timestamp, 'MMMM dd, yyyy hh:mm a');
        const historyStatement = `Created new broker bid on ${formattedTimestamp}`;

        const account = localStorage.getItem('account')
          ? JSON.parse(localStorage.getItem('account'))
          : {};
        const brokerProfile = account.profiles.find(
          (profile) => profile.type === 'broker' && profile?.entity_id === entity_id
        );
        const brokerId = brokerProfile.id;

        const super_bid = {
          order_type: values.order_type,
          producer_id: values.producer_id,
          broker_id: brokerId,
          status: "submitted",
          date_to_be_placed_on_platform: values.date_to_be_placed_on_platform,
          date_to_be_withdrawn_from_platform:
            values.date_to_be_withdrawn_from_platform,
          other_documentation_required: values.other_documentation_required, // make it multiple name and corresponding upload field
          requested_unit_price: values.requested_unit_price,
          special_terms_and_conditions: values.special_terms_and_conditions,
          volume_on_offer: values.volume_on_offer,
          receipt_number: values.receipt_number,

          //auto
          commodity: values.commodity.replace(/ /g, '_').toLowerCase(), // commodity from issued warehouse receipt
          quality: values.quality, // quantity from issued warehouse receipt
          unit_of_measure: "Metric Tonnes", // metric tonnes by default
          volume: values.volume_on_offer, //quantity from issued warehouse receipt
          history: [historyStatement],

          location: values.location, // districts from warehouses
          warehouse_name: values.warehouse_name, // name from warehouses
          delivery: values.delivery, // [1. changed to warehouse location, 2. changed back to input field upon request]
        };

        await dispatch(addNewBrokerSuperBid({ super_bid }));

        if (showModal)
        {
          fetchBrokerBids();
        }
        formik.resetForm();
        closeModal();
      } catch (error)
      {
        if (error.response)
        {
          if (error.response.status === 400)
          {
            setError(error.response.data.message);
          }
          else if (error.response.status === 500)
          {
            setError("Failed to Create Bid.");
          }
        }
        else
        {
          setError("Failed to Create Bid.");
        }
      } finally
      {
        setLoading(false);
      }
    },
  });

  const handleOnBlur = (name, value) => {
    formik.setFieldValue(name, parseFloat(value).toFixed(2));
  };

  const handleChange = (fieldName, selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    formik.setFieldValue(fieldName, selectedValue);

    const account = JSON.parse(localStorage.getItem('account')) || {};
    const brokerProfile = account.profiles?.find(
      (profile) => profile.type === 'broker' && profile?.entity_id === entity_id
    );
    const brokerAccountId = brokerProfile?.account_id;

    if (fieldName === 'order_type')
    {
      if (selectedValue === 'client' || selectedValue === 'professional')
      {
        const filteredProducers = producersData?.filter((producer) =>
          (selectedValue === 'client' &&
            producer.account_id !== brokerAccountId) ||
          (selectedValue === 'professional' &&
            producer.account_id === brokerAccountId)
        );

        if (selectedValue === 'client' && filteredProducers?.length === 0)
        {
          setError('No clients available.');
        } else
        {
          setError('');
        }

        setCurrentProducers(filteredProducers);

        if (selectedValue === 'professional')
        {
          setCommodityOptions([
            { value: 'soya_beans', label: 'Soya Beans' },
            { value: 'wheat', label: 'Wheat' },
            { value: 'sunflower_seed', label: 'Sunflower Seed' },
            { value: 'maize', label: 'Maize' },
            { value: 'cow_peas', label: 'Cow Peas' },
            { value: 'sunflower_cake', label: 'Sunflower Cake' },
            { value: 'gold', label: 'Gold' },
          ]);
        } else
        {
          setCommodityOptions([]);
        }
      } else
      {
        setCurrentProducers([]);
        setCommodityOptions([]);
        setError('');
      }
    }
  };

  return (
    <>
      {showModal ? (
        <div className='fixed inset-0 flex items-center justify-center z-50 overflow-y-auto'>
          <div className='fixed inset-0 bg-black opacity-50'></div>
          <div className='relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto'>
            <div class='flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600'>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white'>
                Bid Form
              </h3>
              <button
                type='button'
                onClick={() => closeModal()}
                class='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                data-modal-toggle='addBrokerOfferModal'
              >
                <svg
                  aria-hidden='true'
                  class='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clip-rule='evenodd'
                  ></path>
                </svg>
                <span class='sr-only'>Close</span>
              </button>
            </div>

            <form
              className='space-y-4 md:space-y-6'
              onSubmit={formik.handleSubmit}
            >
              <ol className='items-center w-full sm:w-auto space-y-4 sm:flex sm:space-x-8 sm:space-y-0'>
                <li className='flex items-center text-green-600 dark:text-green-500 space-x-2.5'>
                  <span className='flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500'>
                    1
                  </span>
                  <span>
                    <h4 className='font-medium leading-tight'>Create New Bid</h4>
                    <p className='text-sm'>
                      Fill in the bid details where appropriate
                    </p>
                  </span>
                </li>
              </ol>
              <div className='container grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                <div>
                  <label
                    htmlFor='order_type'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Bid Type
                  </label>
                  <select
                    name='order_type'
                    value={formik.values.order_type}
                    onChange={(e) => handleChange('order_type', e.target)}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Select Bid Type</option>
                    <option value='client'>Client Bid</option>
                    <option value='professional'>
                      Professional(own) Bid
                    </option>
                  </select>
                  {formik.touched.order_type && formik.errors.order_type && (
                    <p className='text-red-500 text-sm mt-1'>
                      {formik.errors.order_type}
                    </p>
                  )}
                </div>

                {formik.values.order_type === 'client' && (
                  <div>
                    <label
                      htmlFor='producer_id'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Client ID
                    </label>

                    <Select
                      isClearable={true}
                      name='producer_id'
                      value={producerOptions.find(option => option.value === formik.values.producer_id)}
                      onBlur={formik.handleBlur}
                      onChange={handleProducerChange}
                      options={producerOptions}
                    />

                    {formik.touched.producer_id && formik.errors.producer_id && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.producer_id}
                      </p>
                    )}
                  </div>
                )}

                <div>
                  <label
                    htmlFor='commodity'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Commodity
                  </label>


                  <select
                    name="commodity"
                    value={formik.values.commodity}
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue('commodity', e.target.value)}
                    disabled={!formik.values.order_type || commodityOptions.length === 0}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value="">Select Commodity</option>
                    {commodityOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {convertToTitleCase(option.label)}
                      </option>
                    ))}
                  </select>

                </div>

                <div>
                  <label
                    htmlFor='quality'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Quality
                  </label>
                  <select
                    name='quality'
                    value={formik.values.quality}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Select Quality</option>

                    {formik.values.commodity?.toLowerCase() === 'wheat' ? (
                      <>
                        <option value='b1'>B1</option>
                        <option value='b2'>B2</option>
                        <option value='b3'>B3</option>
                        <option value='b4'>B4</option>
                      </>
                    ) : formik.values.commodity === 'cow_peas' ? (
                      <>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                      </>
                    ) : formik.values.commodity?.toLowerCase() === 'gold' ? (
                      <>
                        <option value="24k">24K - Premium Grade (Purity: Pure)</option>
                        <option value="22k">22K - High Grade (Purity: 91.7% - 99.9%)</option>
                        <option value="18k">18K - Standard Grade (Purity: 75.0% - 91.6%)</option>
                        <option value="14k">14K - Basic Grade (Purity: 58.5% - 75.0%)</option>
                        <option value="10k">10K - Entry Grade (Purity: 41.7% - 58.5%)</option>
                      </>
                    ) : (
                      <>
                        <option value='a'>A</option>
                        <option value='b'>B</option>
                        <option value='c'>C</option>
                      </>
                    )}
                  </select>

                </div>

                <div>
                  <label
                    htmlFor='location'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Location
                  </label>
                  <input
                    type='text'
                    name='location'
                    value={formik.values.location}
                    onChange={formik.handleChange}

                    // disabled
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                </div>
                <div>
                  <label
                    htmlFor='location'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Delivery
                  </label>
                  <input
                    type='text'
                    name='delivery'
                    value={formik.values.delivery}
                    onChange={formik.handleChange}

                    // disabled
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                </div>

                <div>
                  <label
                    htmlFor='volume_on_offer'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Volume on Bid <small>{formik.values.commodity?.toLowerCase() === 'gold' ? 'Grams (g)' : '(Metric Tonnes)'}</small>
                  </label>
                  <input
                    step='0.01'
                    type='number'
                    min='0.00'
                    default='0.00'
                    name='volume_on_offer'
                    value={formik.values.volume_on_offer}
                    onChange={formik.handleChange}
                    onBlur={() =>
                      handleOnBlur(
                        'volume_on_offer',
                        formik.values.volume_on_offer
                      )
                    }
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.volume_on_offer &&
                    formik.errors.volume_on_offer && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.volume_on_offer}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='date_to_be_placed_on_platform'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Date to be placed on platform
                  </label>
                  <input
                    type='date'
                    name='date_to_be_placed_on_platform'
                    value={formik.values.date_to_be_placed_on_platform}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.date_to_be_placed_on_platform &&
                    formik.errors.date_to_be_placed_on_platform && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.date_to_be_placed_on_platform}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='date_to_be_withdrawn_from_platform'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Date to be withdrawn from platform
                  </label>
                  <input
                    type='date'
                    name='date_to_be_withdrawn_from_platform'
                    value={formik.values.date_to_be_withdrawn_from_platform}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.date_to_be_withdrawn_from_platform &&
                    formik.errors.date_to_be_withdrawn_from_platform && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.date_to_be_withdrawn_from_platform}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='requested_unit_price'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Requested Unit Price
                  </label>
                  <input
                    step='0.01'
                    type='number'
                    min='0.00'
                    default='0.00'
                    name='requested_unit_price'
                    value={formik.values.requested_unit_price}
                    onChange={formik.handleChange}
                    onBlur={() =>
                      handleOnBlur(
                        'requested_unit_price',
                        formik.values.requested_unit_price
                      )
                    }
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.requested_unit_price &&
                    formik.errors.requested_unit_price && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.requested_unit_price}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='other_documentation_required'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Other Documentation Required (optional)
                  </label>
                  <input
                    type='text'
                    name='other_documentation_required'
                    value={formik.values.other_documentation_required}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.other_documentation_required &&
                    formik.errors.other_documentation_required && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.other_documentation_required}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='special_terms_and_conditions'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Special Terms and Conditions (optional)
                  </label>
                  <input
                    type='text'
                    name='special_terms_and_conditions'
                    value={formik.values.special_terms_and_conditions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.special_terms_and_conditions &&
                    formik.errors.special_terms_and_conditions && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.special_terms_and_conditions}
                      </p>
                    )}
                </div>
              </div>

              <div className='flex space-x-4'>
                <button
                  type='button'
                  onClick={closeModal}
                  className='text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2'
                >
                  Close
                </button>
                <button
                  type='submit'
                  //   disabled={!formik.isValid}
                  className='text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2'
                >
                  {formik.isSubmitting ? 'Creating Bid...' : 'Create Bid'}
                </button>
              </div>
              {error && (
                <div
                  className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                  role='alert'
                >
                  <span className='font-medium'>Error: </span>
                  {error}
                </div>
              )}
              {loading && (
                <div className='text-center'>
                  <div role='status'>
                    <svg
                      aria-hidden='true'
                      className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='currentColor'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentFill'
                      />
                    </svg>
                    <span className='sr-only'>Please wait...</span>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BidModal;