import { Modal } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import Logo from '../Logo';
import NavLogo from '../NavLogo';
import {
  getEntities,
  registerNewMember,
  registerNewProducer,
} from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import countries from '../../utils/countries.json';
import provinces from '../../utils/provinces.json';
import HeaderBanner from '../../layouts/HeaderBanner';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { format } from 'date-fns';

const MemberModal = ({ showModal, closeModal, fetchProducers, cooperativeId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const account = localStorage.getItem('account') ? JSON.parse(localStorage.getItem('account')) : {};
  const cooperativeProfile = account.profiles.find(profile => profile.type === "cooperative");

  const providedcooperativeId = cooperativeId ? cooperativeId : cooperativeProfile?.entity_id;

  const initialValues = {
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    commodities: [],
    contact_address: '',
    province: '',
    country: '',
    history: [],
    type: '',
    contact_block: '',
    contact_camp: '',
    contact_district: '',
    contact_village: '',
    contact_tpin: '',
    contact_nrc: '',
    date_of_birth: '',
    gender: '',

    //company data
    entity_name: '',
    entity_reg_no: '',
    entity_tax_no: '',
  };

  // Define the validation schema using yup
  const validationSchema = yup.object().shape({
    contact_first_name: yup.string().required('Member First Name is required'),
    contact_last_name: yup.string().required('Member Last Name is required'),

    contact_phone: yup.string().required('Member Phone Number is required'),
    commodities: yup.array().min(1, 'Select at least one commodity'),
    contact_address: yup.string().required('Address is required'),
    province: yup.string().required('Province is required'),
    gender: yup.string().required('Gender is required'),
    contact_nrc: yup.string().required('NRC Number is required'),
    // contact_village: yup.string().required('Village is required'),
    // contact_district: yup.string().required('District is required'),
    contact_address: yup.string().required('Address is required'),
    // contact_block: yup.string().required('Block is required'),
    // contact_camp: yup.string().required('Camp is required'),
    // contact_tpin: yup.string().required('TPIN is required'),
    date_of_birth: yup.string().required('Date of Birth is required'),

  });

  // Create a useFormik instance with the form configuration
  const formik = useFormik({
    initialValues,
    validationSchema,
    validate: (values) => {
      const errors = {};
      const currentDate = new Date();
      const selectedDate = new Date(values.date_of_birth);

      // Calculate age
      const age = Math.floor((currentDate - selectedDate) / (365.25 * 24 * 60 * 60 * 1000));

      // Validate age for a cooperative member
      const minAge = 16;
      // const maxAge = 35;

      if (age < minAge)
      {
        errors.date_of_birth = `Member must be at least ${minAge} years old.`;
        // } else if (age > maxAge) {
        //   errors.date_of_birth = `Member must be no older than ${maxAge} years.`;
      }

      return errors;
    },

    onSubmit: async (values) => {
      try
      {
        const combinedName =
          values.contact_first_name + ' ' + values.contact_last_name;
        const generatedPassword = Math.random().toString(36).slice(2, 10);

        const timestamp = new Date();
        const formattedTimestamp = format(timestamp, 'MMMM dd, yyyy hh:mm a');
        const historyStatement = `Created new producer ${combinedName} on ${formattedTimestamp}`;

        const producer = {
          entity_id: providedcooperativeId,
          contact_name: combinedName,
          contact_email: values.contact_email,
          contact_phone: values.contact_phone,
          commodities: values.commodities,
          contact_address: values.contact_address,
          province: values.province,
          country: "Zambia",
          history: [historyStatement],
          type: "coop_member",
          contact_block: values.contact_block,
          contact_camp: values.contact_camp,
          contact_district: values.contact_district,
          contact_village: values.contact_village,
          contact_tpin: values.contact_tpin,
          contact_nrc: values.contact_nrc,
          date_of_birth: values.date_of_birth,
          gender: values.gender,
        };


        const account = {
          first_name: values.contact_first_name,
          last_name: values.contact_last_name,
          mobile: values.contact_phone,
          email: values.contact_email,
          password_hash: generatedPassword,
          verified: 0,
          active: 0,
        };

        const entity = {
          entity_name: values.entity_name,
          entity_reg_no: values.entity_reg_no,
          entity_tax_no: values.entity_tax_no,
          entity_type: values.type,
          status: 'pending',
          verified: 0,
          active: 0,
        };

        const profile = {
          name: values.entity_name,
          roles: [
            'administrator',
            'quality_control',
            'logistics',
            'billing',
            'customer_service',
          ],
          status: 'pending',
          type: 'producer',
        };

        setLoading(true);

        // if (
        // values.type === 'coop_member'
        // )
        // {
        await dispatch(
          registerNewProducer({
            producer,
            account,
            entity: {},
            profile: {},
          })
        );
        // } else if (
        //   values.type === 'commercial' ||
        //   values.type === 'public_entity' ||
        //   values.type === 'cooperative'
        // )
        // {
        //   await dispatch(
        //     registerNewProducer({
        //       producer,
        //       account,
        //       entity,
        //       profile,
        //     })
        //   );
        // }
        fetchProducers();
      } catch (error)
      {
        setError(error.message);
      } finally
      {
        // formik.resetForm();
        closeModal();
        setLoading(false);
      }
    },
  });

  // // Create a useFormik instance with the form configuration
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema,
  //   onSubmit: (values) => {
  //     console.log('Form submitted:', values);
  //   },
  // });

  const options = [
    { value: 'Maize', label: 'Maize' },
    { value: 'Soya Beans', label: 'Soya Beans' },
    { value: 'Wheat', label: 'Wheat' },
    { value: 'Sunflower Seed', label: 'Sunflower Seed' },
    { value: 'cow_peas', label: 'Cow Peas' },
    { value: 'sunflower_cake', label: 'Sunflower Cake' },
    { value: 'gold', label: 'Gold' },
  ];

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    formik.setFieldValue('commodities', selectedValues);
  };

  const handlePhoneChange = (phone) => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    const formattedPhoneNumber =
      phoneNumber && phoneNumber.isValid()
        ? phoneNumber.formatInternational()
        : phone;
    formik.handleChange({
      target: {
        name: 'contact_phone',
        value: formattedPhoneNumber,
      },
    });
  };

  return (
    <>
      {showModal ? (
        <div className='fixed inset-0 flex items-center justify-center z-50 overflow-y-auto'>
          <div className='fixed inset-0 bg-black opacity-50'></div>
          <div className='relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto'>
            <div class='flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600'>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white'>
                Register Member
              </h3>
              <button
                type='button'
                onClick={() => closeModal()}
                class='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                data-modal-toggle='updateProductModal'
              >
                <svg
                  aria-hidden='true'
                  class='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clip-rule='evenodd'
                  ></path>
                </svg>
                <span class='sr-only'>Close</span>
              </button>
            </div>

            <form
              className='space-y-4 md:space-y-6'
              onSubmit={formik.handleSubmit}
            >
              <ol className='items-center w-full sm:w-auto space-y-4 sm:flex sm:space-x-8 sm:space-y-0'>
                <li className='flex items-center text-green-600 dark:text-green-500 space-x-2.5'>
                  <span className='flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500'>
                    1
                  </span>
                  <span>
                    <h4 className='font-medium leading-tight'>
                      Member Registration
                    </h4>
                    <p className='text-sm'>
                      Fill in the member details where appropriate
                    </p>
                  </span>
                </li>
              </ol>
              <div className='container grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                {/* <div>
                  <label
                    htmlFor='type'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Member Type
                  </label>
                  <select
                    name='type'
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Choose Member type</option>
                    <option value='individual'>Individual</option>
                    <option value='cooperative'>Cooperative</option>
                    <option value='commercial'>Commercial</option>
                    <option value='public_entity'>Public Entity</option>
                  </select>
                  {formik.touched.type && formik.errors.type && (
                    <p className='text-red-500 text-sm mt-1'>
                      {formik.errors.type}
                    </p>
                  )}
                </div> */}

                <div>
                  <label
                    htmlFor='contact_first_name'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    First Name
                  </label>
                  <input
                    type='text'
                    name='contact_first_name'
                    value={formik.values.contact_first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_first_name &&
                    formik.errors.contact_first_name && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_first_name}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='contact_last_name'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Last Name
                  </label>
                  <input
                    type='text'
                    name='contact_last_name'
                    value={formik.values.contact_last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_last_name &&
                    formik.errors.contact_last_name && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_last_name}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='contact_email'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Email
                    <span className='text-gray-600 text-xs'> (Optional)</span>
                  </label>
                  <input
                    type='email'
                    name='contact_email'
                    value={formik.values.contact_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_email &&
                    formik.errors.contact_email && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_email}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='contact_phone'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    name='contact_phone'
                    country={'zm'}
                    value={formik.values.contact_phone}
                    onChange={handlePhoneChange}
                    onBlur={formik.handleBlur}
                    containerClass='w-full'
                    buttonClass='dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                    required
                  >
                    <div className='mb-2'></div>
                  </PhoneInput>
                  {formik.touched.contact_phone &&
                    formik.errors.contact_phone && (
                      <p className='text-red-500 text-xs mt-1'>
                        {formik.errors.contact_phone}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='date_of_birth'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Date of Birth
                  </label>
                  <input
                    type='date'
                    name='date_of_birth'
                    value={formik.values.date_of_birth}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.date_of_birth &&
                    formik.errors.date_of_birth && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.date_of_birth}
                      </p>
                    )}
                </div>

                <div className='mb-4'>
                  <label className='block text-sm font-medium text-gray-900 dark:text-white mb-2'>Gender</label>
                  <div className='flex items-center'>
                    <label className={`mr-4 flex items-center ${formik.values.gender === 'male' ? 'text-green-500' : 'text-gray-500'}`}>
                      <input
                        type='radio'
                        name='gender'
                        value='male'
                        checked={formik.values.gender === 'male'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='hidden'
                      />
                      <div
                        className={`w-8 h-8 flex items-center justify-center rounded-full mr-2 ${formik.values.gender === 'male' ? 'bg-green-500' : 'bg-gray-300'
                          }`}
                      >
                        {formik.values.gender === 'male' && (
                          <div className='w-4 h-4 bg-white rounded-full'></div>
                        )}
                      </div>
                      <span>Male</span>
                    </label>
                    <label className={`mr-4 flex items-center ${formik.values.gender === 'female' ? 'text-green-500' : 'text-gray-500'}`}>
                      <input
                        type='radio'
                        name='gender'
                        value='female'
                        checked={formik.values.gender === 'female'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='hidden'
                      />
                      <div
                        className={`w-8 h-8 flex items-center justify-center rounded-full mr-2 ${formik.values.gender === 'female' ? 'bg-green-500' : 'bg-gray-300'
                          }`}
                      >
                        {formik.values.gender === 'female' && (
                          <div className='w-4 h-4 bg-white rounded-full'></div>
                        )}
                      </div>
                      <span>Female</span>
                    </label>
                  </div>
                  {formik.touched.gender && formik.errors.gender && (
                    <p className='text-red-500 text-sm mt-1'>{formik.errors.gender}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='contact_nrc'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    NRC
                  </label>
                  <input
                    type='text'
                    name='contact_nrc'
                    value={formik.values.contact_nrc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_nrc &&
                    formik.errors.contact_nrc && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_nrc}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='contact_tpin'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    TPIN
                  </label>
                  <input
                    type='text'
                    name='contact_tpin'
                    value={formik.values.contact_tpin}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_tpin &&
                    formik.errors.contact_tpin && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_tpin}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='profileType'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Select Commodities
                  </label>
                  <Select
                    name='commodities'
                    value={formik.values.commodities.map((value) => ({
                      value,
                      label: value,
                    }))}
                    onChange={handleChange}
                    options={options}
                    isMulti
                  />
                  {formik.touched.commodities && formik.errors.commodities && (
                    <p className='text-red-500 text-sm mt-1'>
                      {formik.errors.commodities}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='province'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Province / State
                  </label>
                  <select
                    name='province'
                    value={formik.values.province}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Choose a province</option>
                    {provinces.Zambia.map((province, index) => (
                      <option key={index} value={province}>
                        {province}
                      </option>
                    ))}
                  </select>
                  {formik.touched.province && formik.errors.province && (
                    <p className='text-red-500 text-sm mt-1'>
                      {formik.errors.province}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='contact_district'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    District
                  </label>
                  <input
                    type='text'
                    name='contact_district'
                    value={formik.values.contact_district}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_district &&
                    formik.errors.contact_district && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_district}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='contact_village'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Village
                  </label>
                  <input
                    type='text'
                    name='contact_village'
                    value={formik.values.contact_village}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_village &&
                    formik.errors.contact_village && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_village}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='contact_block'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Farm Block
                  </label>
                  <input
                    type='text'
                    name='contact_block'
                    value={formik.values.contact_block}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_block &&
                    formik.errors.contact_block && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_block}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='contact_camp'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Camp
                  </label>
                  <input
                    type='text'
                    name='contact_camp'
                    value={formik.values.contact_camp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_camp &&
                    formik.errors.contact_camp && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_camp}
                      </p>
                    )}
                </div>


                <div>
                  <label
                    htmlFor='contact_village'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Area
                  </label>
                  <input
                    type='text'
                    // name='contact_village'
                    // value={formik.values.contact_village}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {/* {formik.touched.contact_village &&
                    formik.errors.contact_village && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_village}
                      </p>
                    )} */}
                </div>

                <div>
                  <label
                    htmlFor='contact_address'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Address
                  </label>
                  <input
                    type='text'
                    name='contact_address'
                    value={formik.values.contact_address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.contact_address &&
                    formik.errors.contact_address && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.contact_address}
                      </p>
                    )}
                </div>



                {formik.values.type === 'commercial' ||
                  formik.values.type === 'public_entity' ||
                  formik.values.type === 'cooperative' ? (
                  <>
                    <div>
                      <label
                        htmlFor='entity_name'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Entity Name
                      </label>
                      <input
                        type='text'
                        name='entity_name'
                        value={formik.values.entity_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        // Conditionally set 'required' attribute based on customer type
                        required={
                          formik.values.type === 'commercial' ||
                          formik.values.type === 'public_entity' ||
                          formik.values.type === 'cooperative'
                        }
                      />
                      {formik.touched.entity_name &&
                        formik.errors.entity_name && (
                          <p className='text-red-500 text-sm mt-1'>
                            {formik.errors.entity_name}
                          </p>
                        )}
                    </div>

                    <div>
                      <label
                        htmlFor='entity_reg_no'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Entity Registration Number
                      </label>
                      <input
                        type='text'
                        name='entity_reg_no'
                        value={formik.values.entity_reg_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        // Conditionally set 'required' attribute based on customer type
                        required={
                          formik.values.type === 'commercial' ||
                          formik.values.type === 'public_entity' ||
                          formik.values.type === 'cooperative'
                        }
                      />
                      {formik.touched.entity_reg_no &&
                        formik.errors.entity_reg_no && (
                          <p className='text-red-500 text-sm mt-1'>
                            {formik.errors.entity_reg_no}
                          </p>
                        )}
                    </div>

                    <div>
                      <label
                        htmlFor='entity_tax_no'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Entity Tax Number
                      </label>
                      <input
                        type='text'
                        name='entity_tax_no'
                        value={formik.values.entity_tax_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        // Conditionally set 'required' attribute based on customer type
                        required={
                          formik.values.type === 'commercial' ||
                          formik.values.type === 'public_entity' ||
                          formik.values.type === 'cooperative'
                        }
                      />
                      {formik.touched.entity_tax_no &&
                        formik.errors.entity_tax_no && (
                          <p className='text-red-500 text-sm mt-1'>
                            {formik.errors.entity_tax_no}
                          </p>
                        )}
                    </div>
                  </>
                ) : null}
              </div>

              <div className='flex space-x-4'>
                <button
                  type='button'
                  onClick={closeModal}
                  className='text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2'
                >
                  Close
                </button>
                <button
                  type='submit'
                  disabled={!formik.isValid}
                  className='text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2'
                >
                  {formik.isSubmitting
                    ? 'Registering Member...'
                    : 'Register Member'}
                </button>
              </div>
              {error && (
                <div
                  className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                  role='alert'
                >
                  <span className='font-medium'>Error: </span>
                  {error}
                </div>
              )}
              {loading && (
                <div className='text-center'>
                  <div role='status'>
                    <svg
                      aria-hidden='true'
                      className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='currentColor'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentFill'
                      />
                    </svg>
                    <span className='sr-only'>Please wait...</span>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MemberModal;